import React, { useState } from 'react'
import RepFlow from '../sales_reps/RepFlow'


const StaffRegistrationPage = () => {
  const [token, setToken] = useState(null)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [salesRepId, setSalesRepId] = useState('')

  const [loginError, setLoginError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isSalesRepValidated, setIsSalesRepValidated] = useState(false)
  const [isAccountCreated, setIsAccountCreated] = useState(false)
  const [isRepRegistrationComplete, setIsRepRegistrationComplete] = useState(false)

  // console.log('is account created:', isAccountCreated)

  return (
    <div className='sm:pt-8  h-full w-full flex flex-col items-center justify-start sm:justify-center'>
      
    
    <div className='shadow-3xl bg-base sm:w-[650px] h-fit flex flex-col sm:gap-4 gap-2 sm:p-12 p-6 rounded-lg'>
      <h2 className='sm:text-4xl text-2xl font-medium'>Team Member Registration</h2>

      <RepFlow 
        isSalesRepValidated={isSalesRepValidated}
        setIsSalesRepValidated={setIsSalesRepValidated}
        isAccountCreated={isAccountCreated}
        setIsAccountCreated={setIsAccountCreated}
        salesRepId={salesRepId}
        setSalesRepId={setSalesRepId}
        email={email}
        setEmail={setEmail}
        token={token}
        setToken={setToken}
        isRepRegistrationComplete={isRepRegistrationComplete}
        setIsRepRegistrationComplete={setIsRepRegistrationComplete}
      />
    </div>
    </div>
  )
}

export default StaffRegistrationPage