import React, { useState } from 'react'
import axios from 'axios'

const RepProfile = ({ token, setIsRepRegistrationComplete, email }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.post(`${import.meta.env.VITE_REGISTRATION_URL}/complete_rep_profile`, {
        // sales_rep_id: salesRepId,
        first_name: firstName,
        last_name: lastName,
        email: email
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })

      console.log('Response data:', response)

      if (response.status === 200) {
        setIsRepRegistrationComplete(true)
        console.log('Team member created!')
      }
      const { token: responseToken } = response.data
      if (responseToken) {
        
      }
      

      // localStorage.setItem('registrationToken', token)
    } catch (error) {
      console.error('Account Registration Failed:', error)
      if (error.response && error.response.data.error) {
        const { error: errorType, message } = error.response.data
      }
      return null
    }
  }

  return (
    <form className='sm:p-8 p-4 flex flex-col items-center' onSubmit={handleSubmit}>
      <label className='flex flex-col gap-2 mb-2 w-4/5'>
          <div className='text-left'>
            <span>First Name</span>
          </div>
          <input 
            className='border border-content border-opacity-20 rounded-md px-2 py-1'
            id='firstName'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </label>

        <label className='flex flex-col gap-2 mb-2 w-4/5'>
          <div className='text-left'>
            <span>Last Name</span>
          </div>
          <input 
            className='border border-content border-opacity-20 rounded-md px-2 py-1'
            id='lastName'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </label>

        <button onClick={handleSubmit} type='button' className='rounded-2xl shadow-md hover:bg-opacity-20  bg-kwixi-purple w-fit px-12 py-1 mt-8'>Next</button> 
    </form>
  )
}

export default RepProfile