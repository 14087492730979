import React from 'react'
import RepValidation from '../sales_reps/RepValidation'
import RepRegistration from '../sales_reps/RepRegistration'
import RepProfile from '../sales_reps/RepProfile'

const RepFlow = ({
  isSalesRepValidated,
  setIsSalesRepValidated,
  isAccountCreated, 
  setIsAccountCreated,
  salesRepId,
  setSalesRepId,
  email,
  setEmail,
  token,
  setToken,
  isRepRegistrationComplete,
  setIsRepRegistrationComplete
}) => {

  if (!isSalesRepValidated) {
    return (
      <RepValidation 
        salesRepId={salesRepId}
        setSalesRepId={setSalesRepId}
        setSalesRepValidated={setIsSalesRepValidated}
        setToken={setToken}
        setEmail={setEmail}
        email={email}
      />
    );
  }

  if (!isAccountCreated) {
    return (
      <RepRegistration 
        salesRepId={salesRepId}
        token={token}
        setIsAccountCreated={setIsAccountCreated}
        email={email}
        setEmail={setEmail}
      />
    );
  }
  if (!isRepRegistrationComplete) {
    return (
      <RepProfile 
        salesRepId={salesRepId} 
        token={token} 
        email={email}
        setIsRepRegistrationComplete={setIsRepRegistrationComplete}
      />
    )
  }

  return <div>Registration Complete</div>
  
}

export default RepFlow