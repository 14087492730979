import React, { useState } from 'react'
import axios from 'axios'

const RepRegistration = ({ salesRepId, setIsAccountCreated, email, setEmail }) => {
  const [isPasswordFocused, setIsPasswordFocused] = useState(false)
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [password, setPassword] = useState('')
  // const [email, setEmail] = useState('')
  const [repFirstName, setRepFirstName] = useState('')
  const [repLastName, setRepLastName] = useState('')


  const validatePassword = (password) => {
    const errors = {}
    if (password.length < 8) {
      errors.length = 'Password must be at least 8 characters long'
    }
    if (!/[A-Z]/.test(password)) {
      errors.uppercase = 'Password must include at least one uppercase letter';
    }
    if (!/[a-z]/.test(password)) {
      errors.lowercase = 'Password must include at least one lowercase letter';
    }
    if (!/[0-9]/.test(password)) {
      errors.number = 'Password must include at least one number';
    }
    // if (!/[!@#$%^&*]/.test(password)) {
    //   errors.specialChar = 'Password must include at least one special character (!@#$%^&*)';
    // }
    return errors;
  }

  const comparePasswords = (password, confirmPassword) => {
    return password === confirmPassword
  }

  const passwordErrors = validatePassword(password)
  const doPasswordsMatch = comparePasswords(password, confirmPassword)

  const handleSubmit = async (e) => {

    e.preventDefault()

    const token = localStorage.getItem('staffToken')

    try {
      const response = await axios.post(`${import.meta.env.VITE_REGISTRATION_URL}/create_new_rep`, {
        // sales_rep_id: salesRepId,
        email: email,
        password: password

      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })

      console.log('Response data:', response)

      if (response.status === 200) {
        setIsAccountCreated(true)
        console.log('Team member created!')
      }
      const { token: responseToken } = response.data
      if (responseToken) {
        
      }
      

      // localStorage.setItem('registrationToken', token)
    } catch (error) {
      console.error('Account Registration Failed:', error)
      if (error.response && error.response.data.error) {
        const { error: errorType, message } = error.response.data
      }
      return null
    }
  }

  return (
    // <div className='shadow-3xl bg-base sm:w-[650px] h-fit flex flex-col sm:gap-4 gap-2 sm:p-12 p-6 rounded-lg'>
    //   <h2 className='sm:text-4xl text-2xl font-medium'>Team Member Registration</h2>
      <form className='sm:p-8 p-4 flex flex-col items-center' onSubmit={handleSubmit}>
        <label className='flex flex-col gap-2 mb-2 w-4/5'>
          <div className='text-left'>
            <span>Email</span>
          </div>
          <input
            className='border border-content border-opacity-20 rounded-md px-2 py-1'
            id='email'
            value={email}
            // onChange={(e) => setEmail(e.target.value)}
            required
            disabled
          />
        </label>

        <label className='flex flex-col gap-2 mb-2 w-4/5'>
          <div className='text-left'>
            <span>Password</span>
          </div>
          <input 
            className='border border-content border-opacity-20 rounded-md px-2 py-1'
            id='password'
            value={password}
            onFocus={() => setIsPasswordFocused(true)}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {isPasswordFocused && Object.keys(passwordErrors).map((key) => (
                <p key={key} className='text-red-500 text-[11px] text-left leading-none'>{passwordErrors[key]}</p>
              ))}
        </label>

        {password && !Object.keys(passwordErrors).length && (
          <div className='flex flex-col gap-1 w-4/5'>
            <div className='text-left'>
              <span>Confirm Password</span>
            </div>
            <input
              type='password'
              onFocus={() => setIsConfirmPasswordFocused(true)}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className='border border-content border-opacity-20 rounded-md px-2 py-1'
            />
            {isConfirmPasswordFocused && confirmPassword !== password && (
              <p className='text-red-500 text-[11px] text-left leading-none'>Password must match</p>
            )}
            {/* {errors.confirmPassword && <p className='text-red-500'>{errors.confirmPassword.message}</p>} */}
          </div>
        )}

        <button onClick={handleSubmit} type='button' className='rounded-2xl shadow-md hover:bg-opacity-20  bg-kwixi-purple w-fit px-12 py-1 mt-8'>Next</button>  
      </form>
    // </div>
  )
}

export default RepRegistration