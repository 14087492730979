import React from 'react'
import axios from 'axios'

const RepValidation = ({ setSalesRepValidated, setToken, email, setEmail }) => {
  const handleSubmit = async (e) => {

    e.preventDefault()

    try {
      const response = await axios.post(`${import.meta.env.VITE_REGISTRATION_URL}/validate_new_rep`, {
        email: email
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      })

      console.log('Id Validated:', response.data)
      const { token: responseToken } = response.data
      if (responseToken) {
        setToken(responseToken)
        localStorage.setItem('staffToken', responseToken)
        console.log('Team member validated!')
        setSalesRepValidated(true)
      }
      

      // localStorage.setItem('registrationToken', token)
    } catch (error) {
      console.error('Login failed:', error)
      if (error.response && error.response.data.error) {
        const { error: errorType, message } = error.response.data
      }
      return null
    }
  }

  return (
    <form className='sm:p-8 p-4 flex flex-col items-center' onSubmit={handleSubmit}>
      <label className='flex flex-col gap-2 mb-2 w-4/5'>
        <div className='text-left'>
          <span>Email</span>
        </div>
        <input 
          className='border border-content border-opacity-20 rounded-md px-2 py-1'
          id='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </label>

      <button onClick={handleSubmit} type='button' className='rounded-2xl shadow-md hover:bg-opacity-20  bg-kwixi-purple w-fit px-12 py-1 mt-8'>Next</button>  
    </form>
  )
}

export default RepValidation
