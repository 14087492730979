import React from 'react'
import { Link } from 'react-router-dom'
import { 
  FaFacebookF, FaInstagram, 
  FaTwitter, FaLinkedinIn, 
  FaYoutube, FaTiktok } from "react-icons/fa";
import handleOpenChatbot from '../utils/ChatbotOpened';
import useMobileDetect from '../hooks/mobileHook';



const Footer = () => {
  const isMobile = useMobileDetect()

  return (
    !isMobile ? (

    
    <div className='bg-[#F6F6F6] text-[#281A39] p-8 pb-4 shrink-0'>
      
      <div className='flex border-y-2 border-[#C7C4CB] mb-4'>
        <div className='grid grid-cols-4 grid-flow-row gap-y-3 w-full text-[12px] py-12'>

        <Link className='text-[44px] font-semibold text-left tracking-wider'>Kwixi</Link>

          <div className='flex'>
            <ul className='flex flex-col items-start gap-1 font-light'>
              <li><Link to='/plans'>Plans</Link></li>
              <li><Link to='/learn'>Learn</Link></li>
              <li><Link to='/contact'>Contact</Link></li>
              <li><Link to='/signin'>Members</Link></li>
            </ul>
          </div>

          <div className='flex'>
            <address className='not-italic text-left flex flex-col gap-1 '>
              <p>5311 SE Powell Blvd</p>
              <p>Suite 201 Portland, OR 97206</p>
              <p><a>info@kwixi.ai</a></p>
              {/* <p>123-456-7890</p> */}
            </address>
          </div>

          <div className='flex flex-col gap-2 items-start'>
            <div className='flex flex-col gap-1 items-start font-semibold'>
              <p>We're looking for</p>
              <p>talented, passionate</p>
              <p>folks to join our team.</p>
            </div>
            {/* <Link to='/careers' className='hover:border-b hover:border-[#281A39]'>Team Members</Link> */}
          </div>
          
          {/* Grid Placeholder */}
          <div className='sm:flex hidden justify-center'></div>

          <div className='flex'>
            <button onClick={handleOpenChatbot} className='bg-[#281a39] text-[#F4F4F4] hover:bg-slate-300 hover:text-content text-[11px] px-6 h-[30px] rounded-full'>
              Let's chat
            </button>
          </div>

          <div className='flex items-start'>
            <Link className='hover:border-b hover:border-[#281A39]'>About Kwixi</Link>
          </div>

          <div className='flex items-start'>
            {/* <Link to='/agreement' className='border-b border-[#281A39]'>Jobs at Kwixi</Link> */}
            <Link className='hover:border-b hover:border-[#281A39]' to='/demo'>Demo</Link>
          </div>

        </div>
        
      </div>
      
      <div className='flex justify-between'>
        <p className='text-[12px]'>© 2024 by Kwixi</p>

        <div className='flex justify-end gap-2'>
          <Link><FaFacebookF /></Link>
          <Link><FaInstagram /></Link>
          <Link><FaTwitter /></Link>
          <Link><FaLinkedinIn /></Link>
          <Link><FaYoutube /></Link>
          <Link><FaTiktok /></Link>
        </div> 
      </div>
    </div>
    ) : (
      <MobileFooter />
    )
  )
}

export default Footer


const MobileFooter = () => {

  return (
    <div className='bg-[#F6F6F6] text-[#281A39] p-6 pb-4 mt-12 shrink-0'>
      <div className='flex flex-col border-y-2 border-[#C7C4CB] mb-4'>
        <div className='grid grid-cols-2 grid-flow-row p-4 gap-4 text-[11px] w-full'>
          {/* <a className='text-[32px] font-semibold text-left tracking-wider'>Kwixi</a> */}
          <Link to='/' className='text-[32px] font-semibold text-left tracking-wider hover:underline'>Kwixi</Link>
          <div></div>

          <ul className='flex flex-col text-[12px] gap-1 items-start font-semibold'>
            <li><Link className='hover:underline' to='/plans'>Plans</Link></li>
            <li><Link className='hover:underline' to='/learn'>Learn</Link></li>
            <li><Link className='hover:underline' to='/contact'>Contact</Link></li>
            <li><Link className='hover:underline' to='/signin'>Members</Link></li>
          </ul>

          <button onClick={handleOpenChatbot} className='bg-[#281a39] text-[#F4F4F4] hover:bg-slate-300 hover:text-content text-[11px] px-4 h-[30px] rounded-full'>
              Let's chat
          </button>

          <address className='not-italic text-left flex flex-col gap-1 '>
            <p>5311 SE Powell Blvd</p>
            <p>Suite 201 Portland, OR 97206</p>
            <p><a>info@kwixi.ai</a></p>
            {/* <p>123-456-7890</p> */}
          </address>

          <div className='flex flex-col gap-1 content-start items-start font-semibold'>
            <p>We're looking for</p>
            <p>talented, passionate</p>
            <p>folks to join our team.</p>
            <div>Careers</div>
          </div>
          <div className='flex items-start'>
            <Link className='border-b border-[#281A39]'>About Kwixi</Link>
          </div>
          <div className='flex items-start'>
            <Link to='/agreement' className='border-b border-[#281A39]'>Jobs at Kwixi</Link>
          </div>
        </div>


      </div>
      <div className='flex justify-between'>
        <p className='text-[12px]'>© 2024 by Kwixi</p>

        <div className='flex justify-end gap-2'>
          <Link><FaFacebookF /></Link>
          <Link><FaInstagram /></Link>
          <Link><FaTwitter /></Link>
          <Link><FaLinkedinIn /></Link>
          <Link><FaYoutube /></Link>
          <Link><FaTiktok /></Link>
        </div> 
      </div>
    </div>
  )
}